import { Product } from '@/types/SearchAPI/SAPIProduct'
import { checkVariantNameIsSize } from '@/helpers/common'

export const ProductHelpers = {
  getCollectionVariants(product: Product) {
    const { options } = product
    let variants: Product['variants'] = []
    if (options.length === 1 && options[0].display_name === 'Collection') {
      variants = product.variants
    }

    if (!variants.length) {
      return []
    }

    if (variants.every(checkVariantNameIsSize)) {
      [...variants].sort((a, b) => (
        parseFloat(`${a.calculated_price || 0}`) - parseFloat(`${b.calculated_price || 0}`)
      ))
    } else {
      [...variants].sort((a, b) => {
        if (parseFloat(`${b.calculated_price || 0}`) > parseFloat(`${a.calculated_price || 0}`)) {
          return 1
        } if (parseFloat(`${a.calculated_price || 0}`) === parseFloat(`${b.calculated_price || 0}`)) {
          if (a.option_values[0].label > b.option_values[0].label) {
            return 1
          }
        }
        return -1
      })
    }

    return variants
  },
  getProductAttributeBySku(product: Product, sku: string) {
    const optionValues = product.variants.find((variant) => variant.sku === sku)?.option_values
    return optionValues?.reduce((accumulator: number[], optionValue) => {
      accumulator[optionValue.option_id] = optionValue.id
      return accumulator
    }, []) || []
  },
}

export default ProductHelpers
